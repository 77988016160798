import {
  ArrowRightIcon,
  CashIcon,
  ChevronDoubleRightIcon,
  ExternalLinkIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CurrencyInput from "react-currency-input-field";

function IndexPage() {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "id" }}>
        <title>Rupiah.link: Terima pembayaran internasional</title>
        <meta
          name="description"
          content="Cara terbaik untuk menerima pembayaran internasional. Terima pembayaran luar negeri ke rekening bank Indonesia dengan lebih mudah dan murah dibanding PayPal"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://rupiah.link" />
        <meta property="og:title" content="Rupiah.link" />
        <meta
          property="og:description"
          content="Cara terbaik untuk menerima pembayaran internasional"
        />
        <meta
          property="og:image"
          content={`https://rupiah.link${
            require("../images/social.png").default
          }`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:title" content="Rupiah.link" />
        <meta
          name="twitter:description"
          content="Cara terbaik untuk menerima pembayaran internasional"
        />
        <meta
          name="twitter:image"
          content={`https://rupiah.link${
            require("../images/social.png").default
          }`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main className="bg-slate-100 pt-8">
        <nav className="absolute top-0 flex w-full items-center justify-between px-6 py-4 text-right sm:px-8 sm:py-6">
          <div>
            <svg
              aria-hidden="true"
              className="h-6 w-6 sm:h-7 sm:w-7"
              viewBox="0 0 500 500"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M208.333 270.833a104.176 104.176 0 0 0 75.972 41.507 104.16 104.16 0 0 0 81.112-30.257l62.5-62.5A104.168 104.168 0 0 0 280.625 72.292l-35.833 35.625"
                stroke="#38BDF8"
                strokeWidth="41.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M291.666 229.167a104.173 104.173 0 0 0-75.971-41.507 104.176 104.176 0 0 0-81.112 30.257l-62.5 62.5a104.167 104.167 0 0 0 147.292 147.292L255 392.084"
                stroke="#0EA5E9"
                strokeWidth="41.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div>
            <a
              className="inline-flex items-center text-sm text-slate-500 hover:text-slate-700"
              href="https://app.rupiah.link/"
            >
              <UserIcon aria-hidden="true" className="mr-1 h-4 w-4" />
              Login
            </a>
          </div>
        </nav>
        <div className="container mx-auto px-4">
          <div className="flex h-[600px] items-center justify-center text-center">
            <div>
              <div className="mb-4 inline-flex rounded-full bg-gradient-to-r from-pink-200 to-red-200 px-5 py-2 sm:mb-6 sm:py-3">
                <p className="font-display text-xs font-bold uppercase leading-none tracking-wider text-red-600">
                  Untuk kreator Indonesia
                </p>
              </div>

              <div className="mb-6 sm:mb-12">
                <h1 className="bg-gradient-to-br from-sky-500 to-indigo-600 bg-clip-text pb-4 font-display text-3xl font-bold tracking-tight text-transparent sm:pb-6 sm:text-5xl">
                  Cara terbaik untuk
                  <br className="hidden sm:block" /> menerima pembayaran
                  internasional
                </h1>
                <p className="text-sm leading-normal text-slate-700 sm:text-lg">
                  Terima pembayaran luar negeri ke rekening bank Indonesia
                  <br className="hidden sm:block" /> dengan lebih{" "}
                  <b className="text-sky-500 underline underline-offset-2">
                    mudah
                  </b>{" "}
                  dan{" "}
                  <b className="text-sky-500 underline underline-offset-2">
                    murah
                  </b>{" "}
                  dibanding PayPal
                </p>
              </div>

              <a
                className="group inline-flex w-[290px] items-center justify-center rounded-full border-2 border-transparent bg-[#1DA1F2] py-2 text-white shadow-lg shadow-sky-300/70 sm:w-[300px] sm:py-3"
                href="https://app.rupiah.link/"
              >
                <div className="inline-flex translate-x-[10px] items-center">
                  <span className="font-display font-bold leading-none">
                    Buat akun dengan Twitter
                  </span>
                  <ArrowRightIcon
                    aria-hidden="true"
                    className="h-6 w-6 -translate-x-2 opacity-0 transition group-hover:translate-x-2 group-hover:opacity-100"
                  />
                  <svg
                    className="ml-2 h-5 w-5 -translate-x-5 fill-current transition group-hover:translate-x-0 group-hover:opacity-0"
                    aria-hidden="true"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </main>

      <section>
        <div className="container mx-auto px-4 py-12 sm:py-36">
          <h2 className="mb-8 inline-flex items-center text-sm font-semibold uppercase tracking-wider text-slate-500">
            <ChevronDoubleRightIcon
              aria-hidden="true"
              className="mr-1 h-4 w-4"
            />
            Mudah
          </h2>
          <div className="space-y-12 sm:flex sm:space-y-0 sm:space-x-20">
            <div className="flex-1">
              <div className="mb-5 flex">
                <span className="mr-2 mt-px inline-flex h-6 w-6 flex-none items-center justify-center rounded-full bg-sky-500 text-sm font-bold text-white sm:mt-1">
                  1
                </span>
                <h3 className="bg-gradient-to-br from-sky-500 to-indigo-600 bg-clip-text font-display text-xl font-bold leading-tight tracking-tight text-transparent sm:text-2xl">
                  Minta pembayaran dengan mengirimkan link
                </h3>
              </div>
              <div className="ml-8">
                <p className="mb-5 text-slate-700">
                  Hanya perlu menulis jumlah uang dan mengirimkan link.
                </p>
                <a
                  href="https://pay.rupiah.link/keqing/uniqueLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-sm text-sky-500 underline underline-offset-4 hover:text-sky-600"
                >
                  https://pay.rupiah.link/keqing/uniqueLink
                  <ExternalLinkIcon
                    className="ml-1 h-4 w-4"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
            <div className="flex-1">
              <div className="mb-5 flex">
                <span className="mr-2 mt-px inline-flex h-6 w-6 flex-none items-center justify-center rounded-full bg-sky-500 text-sm font-bold text-white sm:mt-1">
                  2
                </span>
                <h3 className="bg-gradient-to-br from-sky-500 to-indigo-600 bg-clip-text font-display text-xl font-bold leading-tight tracking-tight text-transparent sm:text-2xl">
                  Klien membayar dengan kartu kredit atau debit
                </h3>
              </div>
              <div className="ml-8">
                <p className="mb-6 text-slate-700">
                  Rupiah.link mendukung semua kartu yang sering digunakan secara
                  internasional. Klien tidak perlu membuat akun untuk membayar.
                </p>
                <div className="-ml-3 flex">
                  <StaticImage
                    formats={["webp", "jpg"]}
                    placeholder="tracedSVG"
                    height={40}
                    quality={80}
                    src="../images/Mastercard-logo.png"
                    alt="Mastercard"
                  />
                  <StaticImage
                    formats={["webp", "jpg"]}
                    placeholder="tracedSVG"
                    height={40}
                    quality={80}
                    src="../images/Visa-logo.png"
                    alt="VISA"
                  />
                  <StaticImage
                    formats={["webp", "jpg"]}
                    placeholder="tracedSVG"
                    height={40}
                    quality={80}
                    src="../images/Amex-logo.png"
                    alt="American Express"
                  />
                  <StaticImage
                    formats={["webp", "jpg"]}
                    placeholder="tracedSVG"
                    height={40}
                    quality={80}
                    src="../images/JCB-logo.png"
                    alt="JCB"
                  />
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="mb-5 flex">
                <span className="mr-2 mt-px inline-flex h-6 w-6 flex-none items-center justify-center rounded-full bg-sky-500 text-sm font-bold text-white sm:mt-1">
                  3
                </span>
                <h3 className="bg-gradient-to-br from-sky-500 to-indigo-600 bg-clip-text font-display text-xl font-bold leading-tight tracking-tight text-transparent sm:text-2xl">
                  Uang masuk ke rekening bank kamu
                </h3>
              </div>
              <div className="ml-8">
                <p className="text-slate-700">
                  Uang akan masuk ke rekening bank secara otomatis tanpa
                  penarikan manual dan tanpa biaya tambahan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-sky-50">
        <div className="container mx-auto px-4 py-12 sm:py-36">
          <div className="text-center">
            <h2 className="mb-8 inline-flex items-center text-sm font-semibold uppercase tracking-wider text-slate-500">
              <CashIcon aria-hidden="true" className="mr-1 h-4 w-4" />
              Murah
            </h2>
            <p className="mb-6 bg-gradient-to-br from-sky-500 to-indigo-600 bg-clip-text font-display text-xl font-bold leading-tight tracking-tight text-transparent sm:mb-10 sm:text-3xl">
              Tanpa biaya tersembunyi
            </p>
          </div>
          <div className="mx-auto max-w-[400px]">
            <ConversionCheck />
          </div>
        </div>
      </section>

      <section className="bg-slate-900">
        <div className="mx-auto max-w-5xl px-4 py-12 sm:py-36">
          <h2 className="mb-10 inline-flex items-center text-sm font-semibold uppercase tracking-wider text-slate-400">
            <LockClosedIcon aria-hidden="true" className="mr-1 h-4 w-4" />
            Aman
          </h2>
          <div className="space-y-12 sm:flex sm:space-y-0 sm:space-x-20">
            <div className="flex-1">
              <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-br from-sky-300 to-white">
                <ShieldCheckIcon
                  aria-hidden="true"
                  className="h-8 w-8 text-slate-900"
                />
              </div>
              <h3 className="mb-5 font-display text-xl font-bold leading-tight text-sky-200 text-transparent sm:text-2xl">
                Keamanan pembayaran terjamin
              </h3>
              <p className="text-slate-200">
                Pembayaran diproses oleh payment processor pihak ketiga yang
                terkemuka dan terpercaya di Indonesia, yang memastikan keamanan
                pembayaran terjamin.
              </p>
            </div>
            <div className="flex-1">
              <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-br from-sky-300 to-white">
                <UserIcon
                  aria-hidden="true"
                  className="h-8 w-8 text-slate-900"
                />
              </div>
              <h3 className="mb-5 font-display text-xl font-bold leading-tight text-sky-200 text-transparent sm:text-2xl">
                Terima pembayaran secara anonim
              </h3>
              <p className="text-slate-200">
                Kami hanya memberitahu username Twitter dan email kreator kamu
                kepada pembayar, dan tidak nama asli atau email asli.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-slate-800">
        <div className="mx-auto max-w-5xl px-4 py-12 sm:py-36">
          <h2 className="mb-10 text-center font-display text-xl font-bold text-sky-200 text-transparent sm:mb-12 sm:text-left sm:text-3xl">
            Apapun yang kamu jual, kamu dapat <br className="hidden sm:block" />{" "}
            menerima pembayarannya dengan Rupiah.link
          </h2>
          <div className="text-center sm:text-left">
            <a
              className="group inline-flex w-[290px] items-center justify-center rounded-full border-2 border-transparent bg-[#1DA1F2] py-2  text-white shadow-lg shadow-sky-900/40 sm:w-[300px] sm:py-3"
              href="https://app.rupiah.link/"
            >
              <div className="inline-flex translate-x-[10px] items-center">
                <span className="font-display font-bold leading-none">
                  Buat akun dengan Twitter
                </span>
                <ArrowRightIcon
                  aria-hidden="true"
                  className="h-6 w-6 -translate-x-2 opacity-0 transition group-hover:translate-x-2 group-hover:opacity-100"
                />
                <svg
                  className="ml-2 h-5 w-5 -translate-x-5 fill-current transition group-hover:translate-x-0 group-hover:opacity-0"
                  aria-hidden="true"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </section>

      <footer className="bg-slate-900">
        <div className="container mx-auto px-4 py-8">
          <p className="text-center text-sm text-slate-200">
            &copy; Copyright 2022 Rupiah.link
          </p>
        </div>
      </footer>
    </>
  );
}

const numberFormatter = new Intl.NumberFormat("id-ID");

function ConversionCheck() {
  const rate = 14_397.9;

  function calculateFee(priceIDR: number) {
    return Math.ceil(priceIDR * 0.042);
  }

  const initialValue = 100;
  const [usd, setUsd] = useState(initialValue);
  const [idr, setIdr] = useState(
    initialValue * rate - calculateFee(initialValue * rate)
  );

  const fee = calculateFee(idr || 0);

  function onPriceIDRChange(price: number) {
    const fee = calculateFee(price);
    const priceIdr = isNaN(price) ? undefined : price;
    setIdr(priceIdr);
    setUsd(
      isNaN(price)
        ? 0
        : Math.max(
            Math.ceil(((priceIdr + fee) / rate + Number.EPSILON) * 100) / 100,
            0
          )
    );
  }

  function onPriceUSDChange(price: number) {
    const fee = calculateFee(price * rate);
    const priceUsd = isNaN(price) ? undefined : price;
    setUsd(priceUsd);
    setIdr(isNaN(price) ? 0 : Math.max(Math.floor(priceUsd * rate - fee), 0));
  }

  return (
    <div className="mb-1">
      <div className="group overflow-hidden rounded-md border-2 bg-white focus-within:border-sky-600">
        <label
          htmlFor="usd"
          className="block cursor-text bg-white px-5 pt-4 text-slate-500 group-focus-within:text-sky-500"
        >
          Kamu meminta pembayaran sebesar
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-5 pt-1 text-slate-400 sm:text-lg">
            $
          </div>
          <CurrencyInput
            id="usd"
            type="text"
            inputMode="decimal"
            accept="[0-9]+"
            className="w-full border-0 py-4 pr-5 pl-9 pt-1 font-semibold text-slate-600 focus:ring-0 sm:text-lg"
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            allowNegativeValue={false}
            maxLength={4}
            value={usd}
            onValueChange={(value) => {
              onPriceUSDChange(
                Number(value?.replace(/\./g, "").replace(/,/g, "."))
              );
            }}
          />
        </div>
      </div>
      <div className="flex">
        <div className="ml-4 mr-4 w-[2px] bg-slate-500"></div>
        <div className="py-12">
          <p className="text-slate-500">Biaya Rupiah.link (4.2%)</p>
          <div className="relative text-slate-700">
            Rp{numberFormatter.format(fee)}
            <div className="absolute -left-[27px] top-px">
              <div className="flex h-5 w-5 items-center justify-center rounded-full bg-slate-800 text-lg font-bold text-white">
                <span className="-mt-[2px] block">-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="group mb-12 overflow-hidden rounded-md border-2 bg-white focus-within:border-sky-600">
        <label
          htmlFor="idr"
          className="block cursor-text bg-white px-5 pt-4 text-slate-500 group-focus-within:text-sky-500"
        >
          Kamu akan mendapatkan
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-5 pt-1 text-slate-400 sm:text-lg">
            Rp
          </div>
          <CurrencyInput
            id="idr"
            type="text"
            inputMode="decimal"
            accept="[0-9]+"
            className="w-full border-0 py-4 pr-5 pl-12 pt-1 font-semibold text-slate-600 focus:ring-0 sm:text-lg"
            allowDecimals={false}
            decimalSeparator=","
            groupSeparator="."
            allowNegativeValue={false}
            maxLength={8}
            value={idr}
            onValueChange={(value) => {
              onPriceIDRChange(Number(value?.replace(/\./g, "")));
            }}
          />
        </div>
      </div>
      <p className="mb-4 text-slate-500">
        Hasil pembayaran ini adalah yang didapat{" "}
        <b className="font-semibold">setelah</b> dipotong biaya atau yang masuk
        ke rekening bank.
      </p>
      <p className="text-slate-500">
        Hasil pembayaran akan berubah-ubah menyesuaikan kurs pada saat
        pembayaran terjadi.
      </p>
    </div>
  );
}

export default IndexPage;
